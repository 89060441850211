<!-- @Author: Yu_Bo -->
<template>
  <div class='user_roles'>
    <div class="vue_title">
      角色管理
    </div>
    <div class="user_roles_main">
      <!--  -->
      <div class="main_table">
        <el-table :data="list" v-loading="loading" style="width: 100%" header-cell-class-name="table-header"
          cell-class-name="cell-class">
          <template slot="empty">
            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
          </template>
          <el-table-column prop="role_name" label="角色名称"></el-table-column>
          <el-table-column label="创建人">
            <template slot-scope="scope">
              <span>{{scope.row.member.mobile || '--'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间"></el-table-column>
          <el-table-column prop="update_time" label="修改时间"></el-table-column>
          <el-table-column class-name="last_class" label="操作" fixed="right" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="addEditBtn(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="page_box" v-if="total" style="padding-bottom: 20px;">
        <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
          @currentChange='currentChangeBtn'></paging-page>
      </div>
    </div>
  </div>
</template>

<script>
  import PagingPage from '@/components/paging_page/paging_page.vue' //分页
  import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
  export default {
    components: {
      PagingPage,EmptyData
    },
    props: {},
    data() {
      return {
        list: [],
        loading: false,
        //
        page: 1,
        limit: 10,
        total: 0,
      }
    },
    computed: {},
    watch: {},
    created() {
      this.getList()
    },
    mounted() {},
    methods: {
      // 列表
      getList() {
        var that = this
        that.loading=true
        // var params = {
        //   page: that.page,
        //   limit: that.limit,
        // }
        that.$userApi.adminIndex().then(res => {
          that.loading=false
          if (res.code == 1000) {
            // console.log(res)
            that.list=res.result
          } else {
            this.$errMsg(res.message)
          }
        })
      },
      // 改变每页条数
      sizeChangeBtn(val) {
        this.limit = val
        this.page = 1
        this.getList()
      },
      // 改变页数
      currentChangeBtn(val) {
        this.page = val
        this.getList()
      },
      // 编辑
      addEditBtn(info) {
        this.$router.push({
          path: '/roles/edit?id=' + info.id + '&name=' + info.role_name + '&ids=' + info.role_ids
        })
      },
    },
  }
</script>

<style lang='scss' scoped>
  .user_roles {
    width: 100%;

    .user_roles_main {
      width: 100%;
      padding: 20px 20px 0;
    }
  }
</style>
